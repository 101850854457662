@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&family=Mochiy+Pop+P+One&display=swap");

.public-nav {
  &__brand {
    font-weight: "bold";
    font-size: 20px;
    font-family: "Fugaz One";
    @media only screen and (max-width: 750px) {
      font-size: 25px;
    }
  }
  &__toggle {
    text-decoration: none;
    box-shadow: none !important;
    border: none;
    padding-right: 0;
  }
  &__desktop {
    @media only screen and (max-width: 750px) {
      display: none;
    }
    justify-content: end;
    &__navbar {
      &__link {
        color: black !important;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
    }
  }
  &__mobile {
    display: none;
    height: 100% !important;
    transition: transform 0s ease-in-out;
    border: none;

    @media only screen and (max-width: 750px) {
      display: block;
    }
    &__header {
      padding-top: 20px;
      button {
        opacity: 1;
        padding-left: 0 !important;
      }
    }
    &__body {
      &__nav {
        &__link {
          color: black;
          text-align: left;
          font-size: 1.5rem;
          padding: 10px;
          &.become-a-seller {
            border-radius: 5px;
          }
        }
      }
    }
  }
}
