.select-field {


  &__label {
    font-size: 15px;
    margin-bottom: 0.3rem;
    font-weight: 500;
  }

  &__select {
    border-radius: 0.375rem !important;
    color: #9f9f9f;

    &.selected {
      color: black !important;
    }
  }
}
