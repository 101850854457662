.phone-field {
  &__label {
    font-size: 15px;
    margin-bottom: 0.3rem;
    font-weight: 500;
  }

  &__input {
    width: 100% !important;
    color: black !important;
    padding: 0.375rem 4rem !important;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    &:focus {
      &::placeholder {
        opacity: 0.65;
      }
    }

    &::placeholder {
      color: #9f9f9f;
    }
  }

  .special-label {
    margin-bottom: 0.3rem;
    font-weight: 500;
  }
}
