.seller-signup {
  &__container {
    display: flex;
    justify-content: center;

    &__form-title {
      font-size: 40px;
      margin-top: 20px;
      justify-content: center;
      font-family: "Roboto", sans-serif;
      margin-bottom: 30px;

      @media only screen and (max-width: 750px) {
        font-size: 30px;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
      }

    }

    &__form {
      &__message{
        color: green;
        margin-bottom: 50px;
        justify-content: center;

        @media only screen and (max-width: 750px) {
          text-align: center;
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 50px;
        }
      }
      &__content {
        width: 300px;
        margin: auto;
      }
    }
  }
}
