@import url("https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&family=Roboto:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&family=Mochiy+Pop+P+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik+Storm&display=swap');

.home {
  &__container {
    display: flex;
    align-self: center;

    @media only screen and (max-width: 750px) {
      flex-direction: column;
    }

    &__headlines {
      flex-direction: column;
      align-items: center;
      margin: auto;
      @media only screen and (max-width: 750px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 40px;
        padding-top: 0px;
      }

      &__headline {
        font-size: 55px;
        line-height: normal;
        font-family: "Roboto", sans-serif;

        @media only screen and (max-width: 750px) {
          font-size: 40px;
          text-align: left;
        }
      }

      &__sub-headline {
        font-size: 30px;
        margin-bottom: 20px;
        margin-top: 20px;

        @media only screen and (max-width: 750px) {
          font-size: 20px;
          text-align: left;

        }

      }

      &__ctas {
        display: flex;
        justify-content: flex-start;

        button:first-child {
          margin-right: 10px !important;
        }

        @media only screen and (max-width: 750px) {
          justify-content: left;
          button:first-child {
            margin-right: 10px !important;
          }
          button {
            margin-left: -12px;
          }
        }

        &__app-store {
          width: auto;
          &__link {
            padding: 0;
            img:first-child {
              margin-bottom: 10px !important;
              margin-right: 20px;
            }

            &__button {
              @media only screen and (max-width: 750px) {
                padding-left: 0;
              }
              height: 60px;
              width: 200px;
            }
          }
        }
      }
    }

    &__image {
      &__desktop {
        justify-content: center;
        border-radius: 50px;

        &__image {
          padding: 20px;
          border-radius: 30px;
          width: 55%;

          @media only screen and (max-width: 750px) {
            width: 90%;
            margin: 0;
            padding: 40px;
            border-radius: 50px;
          }
        }
      }
    }

  }

  &__info {
    height: 350px;
    border-radius: 15px;
    background-color: black;
    margin-top: 80px;
    margin-bottom: 80px;
    @media only screen and (max-width: 750px) {
      border-radius: 0px;
      margin-right: -20px;
      margin-left: -20px;
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &__info1 {
        color: white;
        font-size: 30px;
        line-height: normal;
        font-family: "Roboto", sans-serif;
        margin-left: 100px;
        margin-right: 100px;

        @media only screen and (max-width: 750px) {
          font-size: 25px;
          text-align: center;
          margin: 0;
          padding: 40px;
          &__info {
            br {
              margin-top: 20px;
            }
          }
        }
      }

      &__info2 {
        color: white;
        font-size: 30px;
        line-height: normal;
        font-family: "Roboto", sans-serif;
        margin-left: 400px;
        margin-right: 400px;
        text-align: center;
        margin-bottom: 20px;

        @media only screen and (max-width: 750px) {
          font-size: 25px;
          text-align: center;
          margin: 0;
          margin-bottom: 30px;
          padding-right: 50px;
          padding-left: 50px;
          &__info {
            br {
              margin-top: 20px;
            }
          }
        }
      }

      &__ctas {
        padding-left: 40px;
        padding-right: 40px;

        @media only screen and (max-width: 750px) {
          width: 100%;
        }
      }
    }
  }

  &__how-it-work {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;

    &__title {
      font-size: 40px;
      margin-bottom: 20px;
      margin-top: 20px;


      @media only screen and (max-width: 750px) {
        font-size: 25px;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  &__validation {
    @media only screen and (max-width: 750px) {
      flex-direction: column;
    }

    &__container {
      &__step {
        @media only screen and (max-width: 750px) {
          justify-content: center;
        }


        &__thumbs-up {
          height: 90px;
          width: 120px;
        }
      }

      &__title {
        font-size: 30px;
        margin-bottom: 20px;
        margin-top: 20px;
        font-weight: 500;
        padding-left: 25px;

        @media only screen and (max-width: 750px) {
          font-size: 25px;
          justify-content: center;
          margin-left: 20px;
          margin-right: 20px;
          padding: 0;
        }
      }

      &__sub-title {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight: bold;
        padding-left: 25px;
        @media only screen and (max-width: 750px) {
          padding-left: 40px;
          padding-right: 40px;
        }
      }

      &__description {
        font-size: 20px;
        font-weight: 500;
        color: #a1a1a1;
        padding-left: 25px;

        @media only screen and (max-width: 750px) {
          padding-left: 40px;
          padding-right: 40px;
        }
      }


      &__image {
      }

      &__ctas {
        margin-top: 20px;
        padding-left: 25px;

        @media only screen and (max-width: 750px) {
          padding-left: 40px;
          padding-right: 40px;
          justify-content: center;
        }

        button:first-child {
          margin-right: 10px !important;
        }

        @media only screen and (max-width: 750px) {
          button {
            margin: 10px;
          }
        }
      }
    }


  }

  &__testimonials {
    border-color: transparent !important;
    height: 350px;
    background-color: #ff3b3b;
    margin-top: 80px;
    margin-bottom: 80px;
    border-radius: 15px;

    @media only screen and (max-width: 750px) {
      border-radius: 0px;
      margin-right: -20px;
      margin-left: -20px;
      height: 750px;
      align-items: center;
    }


    &__testimonial {
      background-color: #ff3b3b;
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 70%;
      margin: auto;
      border: none;

      @media only screen and (max-width: 750px) {
        flex-direction: column;
        margin: 0;
        width: 100%;
        padding: 40px;
      }


      &__body {
        &__user-image {
          width: 180px;
          height: 180px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;

          @media only screen and (max-width: 750px) {
            width: 230px;
            height: 230px;
          }
        }

        &__blockquote {
          &__quote {
            margin: auto;
          }

          &__text {
            color: white;
            font-size: 30px;
            line-height: normal;
            font-family: "Roboto", sans-serif;
            margin-top: 20px !important;

            @media only screen and (max-width: 750px) {
              font-size: 25px;
              text-align: left;
            }

          }

          &__blockquote-footer {
            color: white;
            display: flex;
            align-items: center;

            margin-top: 20px !important;
            font-size: 20px;
            font-weight: 500;

            &__source {
              margin-left: 10px;

              &:hover {
                cursor: pointer;
              }
            }

          }
        }
      }
    }
  }

  &__faqs {
    &__accordion {
      &__item {
        padding: 20px;
        border-radius: 15px !important;
        border: solid 1px #dee2e6 !important;
        margin: 15px;

        @media only screen and (max-width: 750px) {
          padding: 8px;
        }


        &__header {
          button {
            font-size: 22px;
            font-weight: 500;

            @media only screen and (max-width: 750px) {
              font-size: 20px;
              &:after {
                margin-left: 15px;
              }
            }


            &:not(.collapsed) {
              color: black;
              box-shadow: unset;
              background-color: white;
            }

            &:focus {
              box-shadow: unset;
              border-color: white;
            }
          }

        }

        &__body {

        }
      }
    }
  }


}
