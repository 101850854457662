.footer {
  margin-bottom: 20px;
  margin-top: 50px;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }

  &__copyright {
    color: #6c757d;
    text-align: end;

    @media only screen and (max-width: 750px) {
      text-align: center;
      margin-top: 20px;
    }
  }

  &__legal {
    &__infos {
      @media only screen and (max-width: 750px) {
        display: flex;
        flex-direction: column;
      }

      &__term-of-use,
      &__rgpd,
      &__cookies,
      &__contact {
        flex: 0 0 auto;
        width: auto;
        color: #6c757d;
        text-decoration: none;

        &:hover {
          color: #6c757d;
        }

        @media only screen and (max-width: 750px) {
          text-align: center;
        }
      }
    }
  }

  &__socials {
    display: flex;
    justify-content: center;

    &__follow-us {
      display: flex;
      justify-content: center;
      font-size: 20px;
    }

    &__section {
      display: flex;
      text-align: center;
      margin-top: 20px;

      &__icons {
        &__twitter,
        &__instagram {
          margin: 10px;
        }

        svg {
          color: #212529;
        }
      }
    }
  }
}
