.input-field {

  .form-control-lg {
    border-radius: 0.375rem !important;
  }
  &__label {
    font-size: 15px;
    margin-bottom: 0.3rem;
    font-weight: 500;
  }

  &__input {
    &::placeholder {
      color: #9f9f9f;
    }
  }
}
