.primary-button {
  color: white;
  height: 50px !important;
  transition: background-color ease 0.2s, border-color ease 0.2s !important;
  border-style: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;

  &:disabled {
    opacity: 0.5;
    background-color: #ff3b3b !important;
  }

  &.small {
    width: 180px;
  }

  &.medium {
    width: 250px;
  }

  &.large {
    width: 100%;
  }

  &.red-liveshopp {
    background-color: #ff3b3b;

    &:hover {
      background-color: #ed3939;
    }
  }

  &.black-liveshopp {
    background-color: black;
  }
}
