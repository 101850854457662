.navigation {
  margin-bottom: 50px;
  @media only screen and (max-width: 750px) {
    margin-bottom: 50px;
  }
  &__container {
    @media only screen and (max-width: 750px) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
