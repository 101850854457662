.cgu {
  &__container {
    &__content {
      &__badge {
        &__title {
          font-size: 40px;
          line-height: normal;
          padding-left: 0;
          font-family: "Roboto", sans-serif;

          @media only screen and (max-width: 750px) {
            text-align: center;
            font-size: 30px;
            padding-left: 12px;
            margin: 20px;
          }
        }
      }
      &__infos {
        &__description {
          @media only screen and (max-width: 750px) {
            margin: 20px;
          }
          &__title {
            font-size: 30px;
            margin-bottom: 20px;
            margin-top: 20px;

            @media only screen and (max-width: 750px) {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}